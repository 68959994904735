import AppNavbar from "../Navbar/Navbar";
import {useNavigate} from "react-router-dom";
import { useState} from "react";
import {useCookies} from "react-cookie";
import {Button, Container, Form, FormGroup, Input, Label} from "reactstrap";


const Customers = () =>{
    const initialFormState = {
        name: '',
        customerCode:''
    };

    const navigate = useNavigate();
    const [customer,setCustomer] = useState(initialFormState);
    const [cookies] = useCookies(['XSRF-TOKEN'])

    const handleSubmit = async (event) =>{
        event.preventDefault();
        await fetch(window.ENV.BASE_URL+"add_new_customer",{
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(customer)

        });
        setCustomer(initialFormState);
        navigate('/');
    }

    const title ='Create New Customer';

   const handleChange = (event) =>{
       const {name,value} = event.target;
       setCustomer({...customer,[name]:value})
   }

    return(
     <div>
         <AppNavbar/>
         <Container>
             {title}
             <Form onSubmit={handleSubmit}>
                 <FormGroup>
                     <Label for='name'>Customer Name</Label>
                     <Input type='text' name='name' id='name' onChange={handleChange}/>
                 </FormGroup>
                 <FormGroup>
                     <Label for='code'>Customer Code</Label>
                     <Input type='text' name='customerCode' id='code' onChange={handleChange}/>
                 </FormGroup>
                 <FormGroup>
                     <Button color='primary' type='submit'>Save</Button>
                 </FormGroup>
             </Form>
         </Container>
     </div>

    );

}

export default Customers;