import { Link } from 'react-router-dom';
import '../App.css';
import {AppBar, Box, IconButton, Toolbar, Typography} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';

const AppNavbar = ()=>{

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        component={Link} to="/"
                    >
                        <HomeIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Home
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default AppNavbar;