import './App.css';
import "bootswatch/dist/materia/bootstrap.min.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./Home/Home";
import TemplateDetails from "./TemplateDetails/TemplateDetails";
import TemplateList from "./TemplateList/TemplateList";
import Customers from "./Customers/Customer";
import {ThemeProvider} from "@emotion/react";
import theme from "./Utils/Theme";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';



const App = () =>{
  return(
      <ThemeProvider theme={theme}>
        <Router>
             <Routes>
                 <Route exact path="/" element={<Home/>}/>
                 <Route path="/template/:id" exact={true} element={<TemplateDetails/>}/>
                 <Route path="template/:id/copy" exact={true} element={<TemplateDetails/>}/>
                 <Route path="/templates" exact={true} element={<TemplateList/>}/>
                 <Route path="/customers" exact={true} element={<Customers/>}/>
            </Routes>
        </Router>
      </ThemeProvider>

  )
}
export default App;
