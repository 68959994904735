import React, {useEffect, useState} from 'react';
import AppNavbar from "../Navbar/Navbar";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";
import {Typography} from "@mui/material";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

const Home = () =>{
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(undefined);
    const [cookies] = useCookies(['XSRF-TOKEN']);

    useEffect(() => {
        setLoading(true);
        fetch(window.ENV.BASE_URL+'api/user',{credentials:'include'})
            .then(response => response.text())
            .then(body => {
                if (body === '') {
                    setAuthenticated(false);
                } else {
                    setUser(JSON.parse(body));
                    setAuthenticated(true);
                }
                setLoading(false);
            });
    }, [setAuthenticated, setLoading, setUser])

    const login = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ':3000') {
            port = ':8080';
        }
        // redirect to a protected URL to trigger authentication
        window.location.href = `//${window.location.hostname}${port}/outlook/private`;
    }


    const logout = () => {
        fetch('/api/logout', {
            method: 'POST', credentials: 'include',
            headers: { 'X-XSRF-TOKEN': cookies['XSRF-TOKEN'] }
        })
            .then(res => res.json())
            .then(response => {
                window.location.href = `${response.logoutUrl}&returnTo=${window.location.origin}`;
            });
    }

    const message = user ?
        <Typography variant={'h4'}>Welcome, {user.name}!</Typography> :
        <Typography variant={'h4'}>Please log in to manage template</Typography>;


    const button = authenticated ?
        <div>
            <Button variant="text"><Link to="/templates">Manage Mail Template</Link></Button>
            <br/>
            <Button variant="text"><Link to="/customers">Add New Customer</Link></Button>
            <br/>
            <Button varian="text" onClick={logout}>Logout</Button>
        </div> :
        <Button color="secondary" onClick={login}>Login</Button>;

    if (loading) {
        return <p>Loading...</p>;
    }

    return(
        <div>
            <AppNavbar/>
            <Container className="p-2 m-2">
                {message}
                {button}
            </Container>
        </div>
    )
}

export default Home;