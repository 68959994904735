import {createTheme} from '@mui/material/styles';

export default createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#063252',
        },
        secondary: {
            main: '#623a8e',
        }
    }
});