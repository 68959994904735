import AppNavbar from "../Navbar/Navbar";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Form, FormGroup, Input, Label} from "reactstrap";
import {useCookies} from "react-cookie";
import ReactQuill from 'react-quill';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'react-quill/dist/quill.snow.css';
import './templatedetails.css';
import Button from '@mui/material/Button';
import {Typography} from "@mui/material";
import Container from '@mui/material/Container';

const TemplateDetails = () =>{
    const initialFormState = {
        description: '',
        body: '',
        tos: '',
        ccs: ''
    };
    const { id } = useParams();
    const [template,setTemplate] = useState(initialFormState);
    const [customers,setCustomer] = useState([]);
    const [currentCustomerCode,setCurrentCustomerCode] = useState('');
    const [isCopied,setCopied] = useState(false);
    const [cookies] = useCookies(['XSRF-TOKEN'])

    useEffect(()=>{
        if(id !== 'new'){
            fetch(window.ENV.BASE_URL+`get_mailtemplateby_id/${id}`,{ credentials: 'include' })
                .then(response => response.json())
                .then(data => {
                    setTemplate(data);
                })
            if(window.location.pathname.includes('copy')){
                setCopied(true);
                fetch(window.ENV.BASE_URL+'get_all_customers',{credentials: 'include'})
                    .then(response => response.json())
                    .then(data => {
                        setCustomer(data);
                    });
            }
        }else{
            fetch(window.ENV.BASE_URL+'get_all_customers',{credentials: 'include'})
                .then(response => response.json())
                .then(data => {
                    setCustomer(data);
                })
        }
    },[id,setTemplate,setCustomer,currentCustomerCode]);

    const title = <Typography variant="h4">{(template.id && !isCopied) ? 'Edit Template' : 'Add Template'}</Typography>;

    const handleCustomerSelection = (event)=>{
        console.log(event.target.value)
        setCurrentCustomerCode(event.target.value);
    }


    const defaultCustomer =
        <FormGroup>
            <Label for="cselect">Select Customer</Label>
            <Input type="select" name="customer_selection" id="cselect" onChange={handleCustomerSelection}>
                <option value="" selected disabled hidden>Choose here</option>
                {customers.map(customer =>{
                return <option key={customer.customerCode} value={customer.customerCode}>{customer.name}</option>
            })}
            </Input>
        </FormGroup>

    const selectCustomer = (customers.length!==0 || isCopied)? defaultCustomer:null;

    const handleChange = (event) => {
        const { name, value } = event.target
        setTemplate({ ...template, [name]: value })
    }

    const handleSubmit = async (event) => {
       event.preventDefault();

       // backend url must be create_new_template/{customerCode} for new template or copy

       const url = (id === 'new' || isCopied)? window.ENV.BASE_URL+`create_new_template/${currentCustomerCode}`
           : window.ENV.BASE_URL+`update_mail_template/${template.id}`;
       const method = (id === 'new' || isCopied) ? 'POST' : 'PUT';
       await fetch(url,{
          method: method,
          headers : {
              'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
           credentials : 'include',
           body : JSON.stringify(template)
       }).then(response =>{
           if(response.ok){
               Store.addNotification({
                   title: "Done",
                   message: "Change / Creation Done",
                   type: "success",
                   insert: "top",
                   container: "top-full",
                   animationIn: ["animate__animated", "animate__fadeIn"],
                   animationOut: ["animate__animated", "animate__fadeOut"],
                   dismiss: {
                       duration: 5000,
                       onScreen: true
                   }
               });
           }else {
               Store.addNotification({
                   title: "Error",
                   message: "Error on creating / modifying mail template",
                   type: "danger",
                   insert: "top",
                   container: "top-full",
                   animationIn: ["animate__animated", "animate__fadeIn"],
                   animationOut: ["animate__animated", "animate__fadeOut"],
                   dismiss: {
                       duration: 5000,
                       onScreen: true
                   }
               });

           }
       });
    }

    const handleEditorChange = (content,delta,source,editor)=>{
        setTemplate(prevState => {
            return {
                ...prevState,
                body: editor.getHTML()
            }
        })

    };


    return(
     <div>
         <ReactNotifications/>
         <AppNavbar/>
         <Container>
             {title}
             <Form onSubmit={handleSubmit}>
                 {selectCustomer}
                 <FormGroup>
                     <Label for="desc">Subject</Label>
                     <Input type="text" name="description" id="desc" value={template.description || ''}
                            onChange={handleChange} autoComplete="name"/>
                 </FormGroup>
                 <ReactQuill name="body" theme="snow" value={template.body || ''} onChange={handleEditorChange}/>
                 <FormGroup>
                     <Label for="city">TOs</Label>
                     <Input type="text" name="tos" id="tos" value={template.tos || ''}
                            onChange={handleChange} autoComplete="address-level1"/>
                 </FormGroup>
                 <FormGroup>
                     <Label for="city">CCs</Label>
                     <Input type="text" name="ccs" id="ccs" value={template.ccs || ''}
                            onChange={handleChange} autoComplete="address-level1"/>
                 </FormGroup>

                 <FormGroup>
                     <Button color="primary" variant="contained" type="submit">Save</Button>{' '}
                     <Button color="error" variant="contained" component={Link} to="/templates">Cancel</Button>
                 </FormGroup>
             </Form>
         </Container>
     </div>
    );

}

export default TemplateDetails;