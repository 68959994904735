import {useEffect, useState} from "react";
import {Table} from "reactstrap";
import {Link} from "react-router-dom";
import AppNavbar from "../Navbar/Navbar";
import {useCookies} from "react-cookie";
import {IconButton, MenuItem, Select, Typography} from "@mui/material";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Container from '@mui/material/Container';
import DeleteIcon from '@mui/icons-material/Delete';


const TemplateList = ()=>{
    const [templates,setTemplate] = useState([]);
    const [filteredTemplate,setFilteredTemplate] = useState([]); //filtered by customer
    const [customers,setCustomers] = useState([]);
    const [selectedCustomer,setSelectedCustomer] = useState('all');
    const [cookies] = useCookies(['XSRF-TOKEN'])


    useEffect(()=>{
        const requestOptions = {
            method: 'GET',
            redirect: 'follow',
            credentials: 'include'
        };
        fetch(window.ENV.BASE_URL+"get_all_mailtemplate",requestOptions)
            .then(response => response.json())
            .then(data => {
                setTemplate(data);
                setFilteredTemplate(data);
            })
        fetch(window.ENV.BASE_URL+"get_all_customers",requestOptions)
            .then(response => response.json())
            .then(data => {
                setCustomers(data);
            })
    },[]);

    const remove = async (id) =>{
        await fetch(window.ENV.BASE_URL+`delete_mail_template/${id}`, {
            method: 'DELETE',
            headers: {
                'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        }).then(() => {
            let updatedTemplate = [...templates].filter(i => i.id !== id);
            setTemplate(updatedTemplate);
        });
    }

    const templateList = filteredTemplate.map(template =>{
        return <tr key={template.description}>
            <td>{template.customer}</td>
            <td>{template.description}</td>
            <td>
                <ButtonGroup variant="contained">
                    <Button variant="contained" color="secondary" size="small" component={Link} to={"/template/"+template.id}>Edit</Button>
                    <Button variant="contained" color="success" size="small" component={Link} to={"/template/"+template.id+"/copy"}>Copy</Button>
                    <IconButton aria-label="delete" size="small" onClick={()=>remove(template.id)}>
                        <DeleteIcon/>
                    </IconButton>
                </ButtonGroup>
            </td>
        </tr>
    });

    const customerList = customers.map(customer =>{
        return <MenuItem value={customer.name}>{customer.name}</MenuItem>
    });

    const handleSelectChange = (event)=> {
        const customer = event.target.value;
        setSelectedCustomer(customer);
        if(customer === 'all'){
            setFilteredTemplate(templates);
        }else {
            const filtered = templates.filter(template => template.customer === customer);
            setFilteredTemplate(filtered);
        }
    }

    const handleSort = ()=>{
        const sorted = [...filteredTemplate].sort((a,b)=>a.customer.localeCompare(b.customer));
        setFilteredTemplate(sorted);
    }

    return(
        <div>
            <AppNavbar/>
            <Container maxWidth="xl">
                <div className="float-end m-3">
                    <Button variant="contained" color="secondary" component={Link} to="/template/new">Add Template</Button>
                </div>
                <Typography variant={'h3'}>Mail Stationery</Typography>
                <div className="container p-0 m-0">
                    <div className="row">
                        <div className="col-sm">
                            <Typography variant={'h6'}>Filter By Customer</Typography>
                            <Select labelId="customer-select"
                                    id="customer-simple-select"
                                    label="Cus"
                                    value={selectedCustomer}
                                    className="w-50 h-25 m-3"
                                    onChange={handleSelectChange}
                            >
                                <MenuItem value='all'>All</MenuItem>
                                {customerList}
                            </Select>
                        </div>
                        <div className="col-sm">
                            <Typography variant={'h6'}>Sort By Customer</Typography>
                            <Button variant="contained" color="secondary" size="sm" onClick={handleSort}>Sort</Button>
                        </div>
                    </div>
                </div>
                <Table className="mt-4">
                    <thead>
                    <tr>
                        <th width="20%">Customer</th>
                        <th width="60%">Description</th>
                        <th width="20%">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {templateList}
                    </tbody>
                </Table>
            </Container>
        </div>
    )

}

export default TemplateList;